// const PREFIX_API = process.env.VUE_APP_HOST
// const PREFIX_API = process.env.VUE_APP_NEW_HOST
// 本地环境
// const PREFIX_API = 'http://127.0.0.1:6079/'


// 测试环境
// const PREFIX_API = 'http://121.196.61.112:6066/'
// 正式环境
const PREFIX_API = 'https://screening.holosz.com/api/'

// const PREFIX_API = '/api/'

export default {
  // 金山云外链
  getKs3Url: `${PREFIX_API}admin/new/aliyun/sts/token`,
  // users: `${PREFIX_API}/api/web/users`,
  // BlogId: id => `${PREFIX_API}/api/web/blog/${id}`
  studentsWord: `${PREFIX_API}word/studentsWord`,
  exportStudents: `${PREFIX_API}admin/exportStudents`,
  exkaramay: `${PREFIX_API}karamay/xlse/exportStudents`,
  newLogin: `${PREFIX_API}bigv1/login`,
  login: {
    login: `${PREFIX_API}bigv1/newLogin`
  },
  school: { // 学校
    add: `${PREFIX_API}admin/new/addSchool`,
    update: `${PREFIX_API}admin/new/updateSchool`,
    list: `${PREFIX_API}admin/new/getSchoolList`,
    del: `${PREFIX_API}admin/new/delSchool`,
    search: `${PREFIX_API}admin/new/searchSchool`,
    import: `${PREFIX_API}admin/new/importSchool`
  },
  screening: { // 筛查计划
    add: `${PREFIX_API}admin/new/addScreening`,
    list: `${PREFIX_API}admin/new/getScreeningList`,
    update: `${PREFIX_API}admin/new/updateScreening  `,
    del: `${PREFIX_API}admin/new/delScreeningChildren`,
    delPlan: `${PREFIX_API}admin/new/delScreening`,
    search: `${PREFIX_API}admin/new/searchScreening`,
    ScreenedVo: `${PREFIX_API}admin/new/searchScreenedVo`,
    screened: `${PREFIX_API}admin/new/haveAndNoScreened`,
    qrcode: `${PREFIX_API}admin/new/getQrCode`
  },
  Students: {
    import: `${PREFIX_API}xlse/importStudents`, // 导入模板
    export: `${PREFIX_API}admin/exportStudents`, // 导出筛查计划
    imkaramay: `${PREFIX_API}karamay/xlse/importStudents`, // 导入模板
    exkaramay: `${PREFIX_API}karamay/xlse/exportStudents`, // 导出筛查计划
    add: `${PREFIX_API}admin/addChildrenRefractive`, // 新增学生
    list: `${PREFIX_API}admin/listFindChildren`,
    count: `${PREFIX_API}admin/getImportCount`,
    del: `${PREFIX_API}admin/delChildren`,
    update: `${PREFIX_API}admin/updateChildren`
  },
  Sys: { // 账号
    add: `${PREFIX_API}admin/new/addSubordinate`,
    type: `${PREFIX_API}admin/new/enableDisable`, // 切换状态
    update: `${PREFIX_API}admin/new/editSysUser`,
    list: `${PREFIX_API}admin/new/getSysUserList`,
    del: `${PREFIX_API}admin/new/delSysUser`,
    search: `${PREFIX_API}admin/new/searchSysUser`
  },
  select: { // 下拉数据
    school: `${PREFIX_API}admin/new/getSchoolList`,
    grade: `${PREFIX_API}admin/new/listSchoolNewGrade`, // 年级
    class: `${PREFIX_API}admin/new/listSchoolClass`, // 班级
    screening: `${PREFIX_API}admin/new/getScreeningList `
  },
  analysis: { // 分析
    getScreening: `${PREFIX_API}admin/new/getScreening`, // 根据学校年级班级查找筛查计划
    normal: `${PREFIX_API}admin/new/abnormalAndNormal`, // 分析图表 近视率 异常率 屈光率（总人数，已筛查人数）
    degree: `${PREFIX_API}admin/new/abnormalDegree`, // 视力异常和屈光异常人数（轻度，中度，重度）
    data: `${PREFIX_API}admin/new/abnormalData`, // 筛查计划返回班级数据（列表
    curve: `${PREFIX_API}admin/new/visionCurve`, // 筛查计划id
    wordReport: `${PREFIX_API}admin/new/wordReport` // 学生报表
  },
  other: { // 其他
    delChildren: `${PREFIX_API}admin/new/delChildren`, // 删除学生
    getDelChildren: `${PREFIX_API}admin/new/getDelChildren`, // 查询回收站
    recyclingChildren: `${PREFIX_API}admin/new/recyclingChildren` // 回收站撤销学生
  },
  // 后台展示
  management: {
    // 机构
    Organization: {
      // 首页
      baseInformation: `${PREFIX_API}admin/organization/baseInformation`,
      visionDistribution: `${PREFIX_API}admin/organization/visionDistribution`,
      warningNumber: `${PREFIX_API}admin/organization/warningNumber`,
      numberLine: `${PREFIX_API}admin/organization/numberLine`,
      maleFemale: `${PREFIX_API}admin/organization/maleFemale`,
      myopiaAgeGroup: `${PREFIX_API}admin/organization/myopiaAgeGroup`
    },
    // 政府
    government: {
      // 首页
      baseInformation: `${PREFIX_API}admin/government/basicInformation`,
      earlyWarning: `${PREFIX_API}admin/government/earlyWarning`,
      myopiaRate: `${PREFIX_API}admin/government/myopiaRate`,
      newTrend: `${PREFIX_API}admin/government/newTrend`,
      maleFemaleRatio: `${PREFIX_API}admin/government/maleFemaleRatio`,
      trendsInMyopia: `${PREFIX_API}admin/government/trendsInMyopia`,
      degreeMyopia: `${PREFIX_API}admin/government/degreeMyopia`,
      newStatistics: `${PREFIX_API}admin/government/newStatistics`
    },
    // 学校
    school: {
      // 首页
      getSchoolStatistics: `${PREFIX_API}admin/school/getSchoolStatistics`,
      getSchoolNumsLine: `${PREFIX_API}admin/school/getSchoolNumsLine`,
      getSchoolTop: `${PREFIX_API}admin/school/getSchoolTop`,
      getNewGrade: `${PREFIX_API}admin/school/getNewGrade`,
      schoolLock: `${PREFIX_API}admin/school/lock`
    },
    // 班级
    classAndGrade: {
      // 首页
      getClassBaseInformation: `${PREFIX_API}admin/getClassBaseInformation`,
      getBoyAndGirlNums: `${PREFIX_API}admin/getBoyAndGirlNums`,
      getClassEarlyWarning: `${PREFIX_API}admin/getClassEarlyWarning`,
      getClassMyopiaNums: `${PREFIX_API}admin/getClassMyopiaNums`,
      getClassMyopiaRateTopThree: `${PREFIX_API}admin/getClassMyopiaRateTopThree`,
      getClassAbnormalAndNormalNums: `${PREFIX_API}admin/getClassAbnormalAndNormalNums`,
      getFocusStudent: `${PREFIX_API}admin/getFocusStudent`
    },
    // 教师管理
    teacher: {
      getTeacherList: `${PREFIX_API}admin/getTeacherList`,
      deleteTeacher: `${PREFIX_API}admin/deleteTeacher`,
      addTeacher: `${PREFIX_API}admin/addTeacher`,
      editTeacher: `${PREFIX_API}admin/editTeacher`,
      getTeacherById: `${PREFIX_API}admin/getTeacherById`
    },
    // 学生管理
    student: {
      getStudent: `${PREFIX_API}admin/getChildrenList`,
      delStudent: `${PREFIX_API}admin/new/delChildren`,
      addStudent: `${PREFIX_API}admin/addTeacherStudent`,
      editStudent: `${PREFIX_API}admin/editTeacherStudent`,
      getClassId: `${PREFIX_API}admin/getClassId`,
      // 搜索学生
      searchStudent: `${PREFIX_API}admin/school/searchStudent`
    },
    // 超级管理员
    Administrator: {
      // 获取省份
      userprovince: `${PREFIX_API}admin/userprovince`,
      // 获取市级
      userCity: `${PREFIX_API}admin/userCity`,
      // 获取县级
      userArea: `${PREFIX_API}admin/userArea`,
      // 机构
      getHospital: `${PREFIX_API}admin/getHospital`,
      addHospital: `${PREFIX_API}admin/addHospital`,
      delHospital: `${PREFIX_API}admin/delHospital`,
      updateHospital: `${PREFIX_API}admin/updateHospital`,
      // 政府
      government: `${PREFIX_API}admin/getGovernment`,
      // 学校
      findSchoolList: `${PREFIX_API}admin/new/searchSchool`,
      deleteSchool: `${PREFIX_API}admin/school/deleteSchool`,
      // 教师
      getTeacherList: `${PREFIX_API}admin/getTeacherList`,
      // 教师编辑
      editTeacher: `${PREFIX_API}admin/editTeacher`,
      // 教师删除
      deleteTeacher: `${PREFIX_API}admin/deleteTeacher`,
      // 教师新增
      addTeacher: `${PREFIX_API}admin/addTeacher`,
      // 班级
      partnerClassList: `${PREFIX_API}admin/school/partnerClassList`,
      delClass: `${PREFIX_API}admin/school/delClass`,
      // 删除班级
      delNewGradeClass: `${PREFIX_API}admin/school/delNewGradeClass`,
      // 查看班级绑定老师
      searchClassTeacher: `${PREFIX_API}admin/school/searchClassTeacher`,
      //  班级里获取年级
      getGradeList: `${PREFIX_API}admin/school/getGradeList`,
      // 添加班级
      schoolAddClass: `${PREFIX_API}admin/school/addClass`,
      // 添加学校
      addSchool: `${PREFIX_API}admin/new/addSchool`,
      // 编辑学校
      updSchool: `${PREFIX_API}admin/new/updateSchool`,
      // 新增年级
      addNewGrade: `${PREFIX_API}admin/school/addNewGrade`,
      // 获取年级
      getNewGrade: `${PREFIX_API}admin/school/getNewGrade`,
      // 删除年级
      delNewGrade: `${PREFIX_API}admin/school/delNewGrade`,
      // 编辑年级
      editNewGrade: `${PREFIX_API}admin/school/editNewGrade`,
      // 获取班级
      listSchoolClass: `${PREFIX_API}admin/new/listSchoolClass`,
      // 添加班级
      addNewGradeClass: `${PREFIX_API}admin/school/addNewGradeClass`,
      // 编辑班级
      editNewGradeClass: `${PREFIX_API}admin/school/editNewGradeClass`,
      // 获取政府
      getGovernmentList: `${PREFIX_API}admin/getGovernmentList`,
      // 添加政府
      addGovernment: `${PREFIX_API}admin/addGovernment`,
      // 编辑政府
      editGovernment: `${PREFIX_API}admin/editGovernment`,
      // 删除政府
      delGovernment: `${PREFIX_API}admin/delGovernment`,
      // 档案管理全部获取
      getAllChildren: `${PREFIX_API}admin/getAllChildren`,
      // 搜索档案
      searchChildren: `${PREFIX_API}admin/searchChildren`,
      // 获取消息
      getSchoolMessage: `${PREFIX_API}admin/getSchoolMessage`
    },
    // 修改密码
    password: {
      // 获取图形验证码
      getShearCaptcha: `${PREFIX_API}bigv1/getShearCaptcha`,
      getVerifyCode: `${PREFIX_API}bigv1/verifyCode`,
      getsendSMS: `${PREFIX_API}bigv1/sendSMS`,
      getvalidation: `${PREFIX_API}bigv1/validation`,
      editForgetPass: `${PREFIX_API}bigv1/forgetPass`
    },
    // 班级管理
    GradeClass: {
      getNewGradeClass: `${PREFIX_API}admin/school/getNewGradeClass`,
      // 获取历史学生数据
      getHistoryStudent: `${PREFIX_API}admin/school/getHistoryStudent`,
      // 恢复学生
      recoverStudent: `${PREFIX_API}admin/school/recoverStudent`,
      // 搜索历史学生数据
      searchHistoryStudent: `${PREFIX_API}admin/school/searchHistoryStudent`,
      // 学生批量导入
      importClassStudents: `${PREFIX_API}xlse/importClassStudents`,
      // 学生历史数据走势图
      trendChart: `${PREFIX_API}admin/getHistory/trendChart`,
      // 获取学生测试历史数据
      getChildrenTestHistory: `${PREFIX_API}admin/getChildrenTestHistory`
    },
    // 筛查计划
    organization: {
      // 根据筛查计划id获取学校
      getSchoolAndUsers: `${PREFIX_API}admin/new/getSchoolAndUsers`,
      // 获取未过期的筛查计划
      getNotOutDateScreeningList: `${PREFIX_API}admin/new/getNotOutDateScreeningList`
    },
    // 消息中心
    messageCenter: {
      addSchoolMessage: `${PREFIX_API}admin/addSchoolMessage`,
      searchSchoolMessage: `${PREFIX_API}admin/searchSchoolMessage`,
      // 修改消息
      editSchoolMessage: `${PREFIX_API}admin/editSchoolMessage`
    }
  },
  // 动态监测
  namicMonitoring: {
    anbanMonit: `${PREFIX_API}admin/monitoring/getTimeType`,
    getTestNums: `${PREFIX_API}admin/monitoring/getTestNums`,
    getClassChildrene: `${PREFIX_API}admin/monitoring/getClassChildren`,
    getMonigement: `${PREFIX_API}admin/monitoring/getMonitoringManagement`,
    getSchoolGrade: `${PREFIX_API}admin/monitoring/getSchoolGrade`,
    getMonitoringChildren: `${PREFIX_API}admin/monitoring/getMonitoringChildren`,
    findBySchoolId: `${PREFIX_API}admin/school/findBySchool`,
    getSchoolRate: `${PREFIX_API}/admin/monitoring/getSchoolRate`
  }
}
